import ModalFrame from "../../../components/form/ModalFrame";
import { SendToMobile } from "@mui/icons-material";

import { useDisclosure } from "react-use-disclosure";
import { Stack, Tooltip, IconButton } from "@mui/material";
import { Form } from "react-final-form";
import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../redux/snackbar";
import FormInput from "../../../components/form/FormInput";
import FormAddress from "../../../components/form/FormAddress";
import TableIconButton from "../../../components/TableIconButton";
import WarningIcon from "@mui/icons-material/Warning";
import FormDateInput from "../../../components/form/FormDateInput";
import FormTimeInput from "../../../components/form/FormTimeInput";

export default function CreateTaskAction({ selectedRows = [] }) {
	const { isOpen, open, close } = useDisclosure(false);
	const dispatch = useDispatch();

	const GQLCreatePackage = gql`
		mutation createPackage(
			$ownerEmail: String
			$address: JSONObject!
			$title: String!
			$description: String!
			$categoryId: Int!
			$subcategoryId: Int
			$price: Float!
			$userTerms: String
			$providerTerms: String
			$workDate: DateTime
			$media: [JSONObject]
			$acquisitionMethodId: Int
			$externalId: String
			$totalPrice: Float!
			$adminNotes: String
			$revenueRate: Float
		) {
			createdPackage: createPackage(
				ownerEmail: $ownerEmail
				address: $address
				title: $title
				description: $description
				categoryId: $categoryId
				subcategoryId: $subcategoryId
				price: $price
				userTerms: $userTerms
				providerTerms: $providerTerms
				workDate: $workDate
				media: $media
				acquisitionMethodId: $acquisitionMethodId
				externalId: $externalId
				totalPrice: $totalPrice
				adminNotes: $adminNotes
				revenueRate: $revenueRate
			) {
				id
			}
		}
	`;

	const [CreatePackage] = useMutation(GQLCreatePackage, { fetchPolicy: "network-only" });

	const createTaskInApp = async (pkg) => {
		const { acquisitionMethodId, title, description, email, address, price, workDate, workTime, userTerms, providerTerms, externalId, totalPrice, adminNotes, revenueRate } = pkg;
		const selectedBundleTask = selectedRows[0];
		workDate.setHours(workTime.getHours());
		workDate.setMinutes(workTime.getMinutes());

		const { data: { createdPackage } = {}, errors } = await CreatePackage({
			variables: {
				price: parseFloat(price),
				ownerEmail: email,
				address,
				workDate,
				title: title ?? selectedBundleTask?.taskData?.title,
				description: description ?? selectedBundleTask?.taskData?.description,
				categoryId: selectedBundleTask?.taskData?.categoryId,
				subcategoryId: selectedBundleTask?.taskData?.subcategoryId ?? null,
				userTerms: userTerms ?? selectedBundleTask?.taskData?.userTerms,
				providerTerms: providerTerms ?? selectedBundleTask?.taskData?.providerTerms,
				media: selectedBundleTask?.taskData?.media,
				acquisitionMethodId,
				externalId,
				adminNotes,
				totalPrice: parseFloat(totalPrice),
				revenueRate: parseFloat(revenueRate),
			},
		});
		if (errors || !createdPackage) dispatch(setSnackBar({ text: "Ocurrio un error al crear el paquete.", severity: "error" }));
		else if (createdPackage) {
			dispatch(setSnackBar({ text: "Tarea creada correctamente." }));
			close();
		}
	};

	const validate = (values) => {
		const errors = {};
		// fillable fields
		if (!values.workDate) errors.workDate = "Required";
		if (!values.workTime) errors.workTime = "Required";
		if (!values.email) errors.email = "Required";
		if (!values?.address?.lat) errors.address = "Required";

		// auto filled fields
		if (!values.title) errors.title = "Required";
		if (!values.price) errors.price = "Required";
		if (!values.description) errors.description = "Required";
		if (!values.userTerms) errors.userTerms = "Required";
		if (!values.providerTerms) errors.providerTerms = "Required";

		return errors;
	};

	return (
		<>
			<TableIconButton onClick={open} disabled={selectedRows.length !== 1} icon={<SendToMobile />} tooltip="Crear tarea empaquetada" />
			<Form
				onSubmit={createTaskInApp}
				initialValues={{ ...selectedRows[0]?.taskData }}
				validate={validate}
				render={({ handleSubmit, values, valid }) => (
					<ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title="Generar paquete en Manno" submitDisabled={!valid} buttonTexts={["Enviar", "Cancelar"]}>
						<Stack spacing={4}>
							<Stack direction={"row"} gap={2}>
								<FormInput label="Email" name="email" autoFocus required />
								<FormDateInput name="workDate" label="Fecha del trabajo" disablePast required />
								<FormTimeInput name="workTime" label="Hora del trabajo" required />
							</Stack>
							<FormInput label="Id Externo" name="externalId" required />

							<FormAddress label="Direccion" name="address" required />
							<FormInput label="Notas" name="adminNotes" type="textarea" />

							{/* datos precargados */}
							<Stack direction="row" gap={2}>
								<FormInput label="Título" name="title" required />
								<FormInput label="Importe a abonar al experto" name="price" type="number" required fullWidth={false} />
								<FormInput label="% de Ganancia" name="revenueRate" type="number" required fullWidth={false} />
							</Stack>
							<Stack direction="row" gap={2}>
								<FormInput label="Descripcion" name="description" required />
								<FormInput label="Importe cobrado al usuario" name="totalPrice" type="number" required fullWidth={false} />
							</Stack>
							<Stack direction="row" spacing={2}>
								<FormInput label="TyC Usuario" name="userTerms" multiline rows={8} />
								<FormInput label="TyC Expertos" name="providerTerms" multiline rows={8} />
							</Stack>
						</Stack>
					</ModalFrame>
				)}
			/>
		</>
	);
}
